import(/* webpackMode: "eager" */ "/github/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/github/workspace/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/github/workspace/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["FirstViewSlider"] */ "/github/workspace/src/app/(normal_header)/_components/FirstViewSlider/index.tsx");
;
import(/* webpackMode: "eager" */ "/github/workspace/src/app/(normal_header)/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["BottomBanner2"] */ "/github/workspace/src/components/BottomBanner2/index.tsx");
;
import(/* webpackMode: "eager" */ "/github/workspace/src/components/ContactAdd/index.module.scss");
;
import(/* webpackMode: "eager" */ "/github/workspace/src/components/AzureSectionUpperCard/index.module.scss");
;
import(/* webpackMode: "eager" */ "/github/workspace/src/components/AzureSectionBottomCard/index.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["FadeLogoSection"] */ "/github/workspace/src/components/FadeLogoSection/index.tsx");
